<template>
    <div class="page-wrapper plug">
        <div class="container" style="height: 100%;">
            <div class="row" style="height: 100%; row-gap: 2rem;">
                <div class="col-lg-5">
                    <div class="text-center">
                        <router-link to="/">
                            <img class="logo-img" src="@/img/logo-1.png" alt="Klub Partnerów Pirelli"/>
                        </router-link>
                    </div>
                </div>
                <div class="col-12 text-center d-flex justify-content-center align-items-center">
                    <h2>Już od 4.02.2025 r. zapraszamy <br/> do nowej edycji Klubu Partnerów Pirelli.</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PlugComponent",
}
</script>

<style lang="scss">
.page-wrapper.plug {
    background-image: url("@/img/plug-bg.png");
    height: 100vh;
    @media (min-width: 992px) {
        &-page-text {
            max-width: 550px;
            margin: 9rem 0 9.5rem;
        }

        .box {
            margin-top: 7.5rem;
        }
    }
    .logo-img{
        @media screen and (max-width: 1199px) {
            max-width: 300px;
        }
        @media screen and (max-width: 768px) {
            max-width: 200px;
        }
    }
}
</style>
