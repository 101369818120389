<template>
    <div>
        <transition name="fade" mode="out-in">
            <div v-if="systemStarted === null" class="loader">
                <loader-component size="lg"/>
            </div>
            <router-view v-else-if="systemStarted"/>
            <plug-component v-else/>
        </transition>
    </div>
</template>

<script>
import axios from "axios";
import PlugComponent from "@/views/PlugComponent";
import LoaderComponent from "@/components/modules/LoaderComponent";

export default {
    name: 'App',
    components: {LoaderComponent, PlugComponent},
    data() {
        return {
            systemStarted: null,
        }
    },
    async beforeCreate() {
        await axios.get('/system-started')
            .then(response => {
                this.systemStarted = response.data;
            })
            .catch(() => {
                this.systemStarted = false
            });
    }
}
</script>

<style lang="scss">
@import "@/styles/app";

.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
